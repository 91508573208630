import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { showError, showSuccess } from '../../services/toastService';
import { Button, Modal } from 'antd';

const { confirm } = Modal;

function showConfirm(onConfirm, onCancel) {
    confirm({
        title: 'Confirm Logout',
        content: 'Are you sure want to logout?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            if (onConfirm) {
                onConfirm();
            }
        },
        onCancel() {
            if (onCancel) {
                onCancel();
            }
        },
    });
}

function Aside({ userData }) {
    const { logout } = useAuth();

    const handleLogout = async () => {
        showConfirm(
            async () => {
                try {
                    await logout()
                    showSuccess('Logout successful!');
                } catch (error) {
                    console.log(error)
                    showError('Invalid credentials'); // Show error message
                }
            },
            () => {
                console.log('Delete canceled');
            }
        );
    };

    const [activeMenu, setActiveMenu] = useState(null);
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const toggleMenu = (menuId) => {
        setActiveMenu(activeMenu === menuId ? null : menuId);
    };

    const toggleSubmenu = (menuId) => {
        setActiveSubmenu(setActiveSubmenu === menuId ? null : menuId);
    };

    return (
        <>
            <aside className="main-sidebar sidebar-dark-primary elevation-3">
                <a href="#" className="brand-link bg-white">
                    <img src="assets/images/pluss.png" alt="AdminLTE Logo" className="brand-images" />
                    <span className="brand-text font-weight-light">
                        {/* <p style={{ minHeight: 20 }}></p> */}
                    </span>

                </a>
                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">

                        <div className="info">
                            <a href="#" className="d-block">Hello! {userData?.name}</a>
                        </div>
                    </div>
                    <nav className="mt-2">
                        {userData.role === 'SUPERADMIN' && <>
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item" key={`dashboard`}>
                                    <Link to="dashboard" className="nav-link" >
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p>Dashboard</p>
                                    </Link>
                                </li>
                                <li className="nav-item" key={`clients`}>
                                    <Link to="clients" className="nav-link" >
                                        <i className="nav-icon fas fa-users"></i>
                                        <p>Clients</p>
                                    </Link>
                                </li>
                                <li className="nav-item" key={`devices`}>
                                    <Link to="devices" className="nav-link" >
                                        <i className="nav-icon fa fa-hdd"></i>
                                        <p>Devices</p>
                                    </Link>
                                </li>

                                <li className="nav-item" key={`logout`}>
                                    <a href="#" className="nav-link" onClick={handleLogout}>
                                        <i className="nav-icon fa fa-sign-out-alt"></i>
                                        <p>Logout</p>
                                    </a>
                                </li>
                            </ul>
                        </>}
                        {userData.role === 'CLIENT' && <>
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item" key={`dashboard`}>
                                    <Link to="dashbaord" className="nav-link" >
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p>Dashboard</p>
                                    </Link>
                                </li>
                                <li className="nav-header"> Device Management</li>

                                <li className={`nav-item ${activeMenu === 'device' ? 'menu-is-opening menu-open' : ''}`} key={`devices`}>
                                    <NavLink
                                        to="#"
                                        className="nav-link"
                                        onClick={() => toggleMenu('device')}
                                        aria-expanded={activeMenu === 'device'}
                                    >
                                        <i className="nav-icon fas fa-hdd"></i>
                                        <p>
                                            Device
                                            <i className={`right fas ${activeMenu === 'device' ? 'fa-angle-down' : 'fa-angle-left'}`}></i>
                                        </p>
                                    </NavLink>
                                    <ul className={`nav nav-treeview ${activeMenu === 'device' ? 'd-block' : 'd-none'}`}>
                                        {/* Level 2 */}
                                        {userData?.deviceList && userData.deviceList.map((device, index) => (
                                            <li className={`nav-item ${activeSubmenu === 'statistics' ? 'menu-is-opening menu-open' : ''}`} key={`dashboard-${index}`}>
                                                <NavLink
                                                    to="#"
                                                    className="nav-link"
                                                    onClick={() => toggleSubmenu('statistics')}
                                                    aria-expanded={activeSubmenu === 'statistics'}
                                                >
                                                    <i className="fas fa-digital-tachograph nav-icon"></i>
                                                    <p>
                                                        {device.name}
                                                        <i className={`right fas ${activeSubmenu === 'statistics' ? 'fa-angle-down' : 'fa-angle-left'}`}></i>
                                                    </p>
                                                </NavLink>
                                                <ul className={`nav nav-treeview ${activeSubmenu === 'statistics' ? 'd-block' : 'd-none'}`}>
                                                    {/* Level 3 */}
                                                    <li className="nav-item">
                                                        <NavLink to={`graphical?device=${device.device_id}`} className="nav-link" activeClassName="active">
                                                            <i className="fas fa-digital-tachograph nav-icon"></i>
                                                            <p>Graphical Data</p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={`tabular?device=${device.device_id}`} className="nav-link" activeClassName="active">
                                                            <i className="fas fa-table nav-icon"></i>
                                                            <p>Tabular Data</p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={`export?device=${device.device_id}`} className="nav-link" activeClassName="active">
                                                            <i className="fas fa-download nav-icon"></i>
                                                            <p>Export Data</p>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                        ))}

                                    </ul>
                                </li>

                                <li className="nav-header">Administration</li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link" onClick={handleLogout}>
                                        <i className="nav-icon fa fa-sign-out-alt"></i>
                                        <p>Logout</p>
                                    </a>
                                </li>
                            </ul>
                        </>}
                    </nav>
                </div >
            </aside >
        </>

    );
}

export default Aside;