import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';


// Use dayjs plugins
dayjs.extend(utc);
// Array to store chart instances globally
let charts = [];

const HighChartJs = ({ data, title, valueField, chartdiv }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        // Prepare the chart data in Highcharts format
        const formattedData = data.map(item => ({
            x: dayjs(`${item.Date} ${item.Time}`, 'YYYY-MM-DD HH:mm:ss').utc(5.30).valueOf(),
            y: parseFloat(item[valueField]),
        }));
        // console.log(formattedData)
        // Sort the data by timestamp in ascending order


        // Set the chart options
        const chartOptions = {
            chart: {
                type: 'line',
                zoomType: 'x',
                events: {
                    load: function () {
                        // Add chart instance to the global charts array
                        charts.push(this);
                        if (charts.length > 1) {
                            syncCharts(); // Sync charts when the first chart is loaded
                        }
                    },
                },
            },
            title: {
                text: null, // Hide the title
            },
            xAxis: {
                type: 'datetime',
                crosshair: true,
                gridLineWidth: 1, // Add vertical line grid
            },
            yAxis: {
                title: {
                    text: title, // Y-axis title
                },
                gridLineWidth: 1, // Add horizontal line grid
            },
            tooltip: {
                shared: true, // Shared tooltip for synced charts
                formatter: function () {
                    const points = this.points.map(p => `${p.series.name}: ${p.y}`).join('<br/>');
                    return `Date: ${Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x)}<br/>${points}`;
                },
            },
            credits: {
                enabled: false, // Disable the Highcharts branding
            },
            legend: {
                enabled: false, // Hide the legend
            },
            series: [
                {
                    name: '', // Hide the series name
                    data: formattedData,
                    marker: {
                        enabled: true,
                        radius: 3,
                    },
                    lineWidth: 2,
                },
            ],
        };

        setOptions(chartOptions);
    }, [data, title, valueField]);

    const syncCharts = () => {
        const handleMouseMove = e => {
            const point = charts[0].pointer.normalize(e); // Normalize mouse position relative to the first chart
            charts.forEach(chart => {
                const hoverSeries = chart.series[0];
                const hoverPoint = hoverSeries.searchPoint(point, true);
                if (hoverPoint) {
                    charts.forEach(syncChart => {
                        if (syncChart !== chart) {
                            const syncSeries = syncChart.series[0];
                            const syncPoint = syncSeries.points.find(p => p.x === hoverPoint.x);
                            if (syncPoint) {
                                syncChart.tooltip.refresh(syncPoint); // Show tooltip on the synced chart
                                syncChart.xAxis[0].drawCrosshair(null, syncPoint); // Draw the crosshair
                            }
                        }
                    });
                }
            });
        };

        charts.forEach(chart => {
            chart.container.addEventListener('mousemove', handleMouseMove);
        });

        return () => {
            charts.forEach(chart => {
                chart.container.removeEventListener('mousemove', handleMouseMove);
            });
        };
    };

    useEffect(() => {
        syncCharts();
    }, [options]); // Update synchronization when options change

    return <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ id: chartdiv }} />;
};

export default HighChartJs;
