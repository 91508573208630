import React, { useState, useEffect } from 'react';
import { getDevice, createDevice, updateDevice } from '../../services/deviceService';
import { getClients } from '../../services/clientService';
import DataTable from 'react-data-table-component';
import { showError, showSuccess } from '../../services/toastService';
import Select2Component from '../../components/select2';
import DeviceFormModal from './form';
import { Button } from 'antd';


function DevicesTable() {
    const [devices, setDevices] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentDevice, setCurrentDevice] = useState();

    const columns = [
        {
            name: 'Device ID',
            selector: row => row.device_id,
            sortable: true,
        },
        {
            name: 'Device Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Device Mac',
            selector: row => row.mac,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: row => row?.user?.username,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <Button type='dashed' onClick={() => handleEditClick(row)}>
                    <i className='fa fa-edit'></i> Edit
                </Button>
            ),
        },
    ];

    const fetchDevice = async () => {
        try {
            setLoading(true);
            const response = await getDevice()
            setDevices(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDevice();
    }, []);


    const handleEditClick = (client) => {
        setCurrentDevice(client)
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        fetchDevice();
    }

    const handleAddClick = async () => {
        setCurrentDevice({ id: 0, device_id: '', device_location: '', device_mac: '', device_key: '', device_name: '', assigned_to: '' });
        setIsModalOpen(true)
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
            },
        },
    };


    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Devices</h3>
                            <div className='float-right'>
                                <Button type='primary' onClick={handleAddClick}>
                                    <i className='fa fa-plus'></i> Add Device
                                </Button>
                            </div>
                        </div>
                        <div className="card-body">
                            <DataTable
                                //title="Clients"
                                columns={columns}
                                data={devices}
                                progressPending={loading}
                                pagination
                                // selectableRows
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <DeviceFormModal
                isVisible={isModalOpen}
                deviceData={currentDevice}
                onClose={handleCancel}
            />
        </div>
    );
}

export default DevicesTable;
