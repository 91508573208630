// src/services/authService.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + 'users'; // Replace with your actual API base URL

const apiService = axios.create({
    baseURL: API_BASE_URL,
});

// Add a request interceptor to include the token in headers for authenticated requests
apiService.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Define CRUD operations

// Create
export const createClient = async (data) => {
    try {
        // console.log(data)
        const response = await apiService.post('/', data);
        return response;
    } catch (error) {
        throw error.response.data;
    }
};


export const getClients = async (filter) => {
    try {
        const params = new URLSearchParams(filter).toString();
        const response = await apiService.get(`/?${params}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getClientById = async (id) => {
    try {
        const response = await apiService.get(`/${id}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

// Update
export const updateClient = async (itemId, data) => {
    try {
        const response = await apiService.put(`/${itemId}`, data);
        return response;
    } catch (error) {
        throw error.response.data;
    }
};

// Delete
export const deleteClient = async (itemId) => {
    try {
        const response = await apiService.delete(`/${itemId}`);
        return response;
    } catch (error) {
        throw error.response.data;
    }
};



export default apiService;



