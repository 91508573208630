import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Select } from 'antd';
import { getClients } from '../../services/clientService';
import { createDevice, updateDevice, getDeviceDetails } from '../../services/deviceService';
import { showError, showSuccess } from '../../services/toastService';

const DeviceFormModal = ({ isVisible, onClose, deviceData }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState(false);

    const getDeviceData = async () => {
        try {
            setLoading(true);
            const device_id = form.getFieldValue('device_id');

            const response = await getDeviceDetails(device_id)
            console.log(response)
            if (response.status === 200) {
                form.setFieldsValue({
                    name: response.type,
                    mac: response.mac,
                    lat: response.lat,
                    lon: response.lon,
                });
            } else {
                form.setFieldsValue({
                    name: '',
                    mac: '',
                    lat: '',
                    lon: '',
                });
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };


    const fetchClients = async () => {
        try {
            setLoading(true);
            const response = await getClients()
            const clientOptions = response.data.map(client => ({
                value: client.id, // Assuming 'id' is the unique identifier
                label: client.username // Assuming 'name' is the display name
            }));
            setCustomers(clientOptions);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClients()
    }, []);

    useEffect(() => {
        if (deviceData) {
            form.setFieldsValue({
                device_id: deviceData.device_id || '',
                device_name: deviceData.name || '',
                device_mac: deviceData.mac || '',
                device_lat: deviceData.lat || '',
                device_lon: deviceData.lon || '',
                assigned_to: parseInt(deviceData.assigned_to) || '',
            });
        }

    }, [deviceData, form]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();

            setLoading(true);
            let response;
            if (!deviceData || !deviceData.id) {
                // Create a new client
                response = await createDevice(values);
            } else {
                // Update existing client
                response = await updateDevice(deviceData.id, values);
            }

            if (response.data.status) {
                showSuccess(response.data.message);
                form.resetFields();
                onClose();
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                switch (error.response.status) {
                    case 409:
                        showError('Client with this email/username already exists');
                        break;
                    case 400:
                        showError('Invalid data provided');
                        break;
                    case 404:
                        showError('Device not found');
                        break;
                    default:
                        showError('An unexpected error occurred');
                        break;
                }
            } else {
                showError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Device Information"
            open={isVisible}
            onOk={handleSubmit}
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            okText={deviceData && deviceData.id ? 'Update Device' : 'Add Device'}
            cancelText="Cancel"
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                name="customerForm"
                initialValues={deviceData || { device_id: '', device_name: '', device_key: '', device_mac: '', device_location: '', assigned_to: '' }}
            >
                <div className="row">
                    <div className="col-8">
                        <Form.Item
                            name="device_id"
                            label="Device ID"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter device id!',
                                },
                            ]}
                        >
                            <Input placeholder="Device ID" />

                        </Form.Item>
                    </div>
                    <div className="col-4">
                        <label>Click to fetch data</label>
                        <Button onClick={() => getDeviceData()} >Get Device Details</Button>
                    </div>
                </div>

                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the device name!',
                        },
                    ]}
                >
                    <Input placeholder="Device name" />
                </Form.Item>



                <Form.Item
                    name="mac"
                    label="Device Mac Address"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter mac address!',
                        },
                    ]}
                >
                    <Input placeholder="Enter MAC Address" />
                </Form.Item>

                <Form.Item
                    name="lat"
                    label="Device Lat"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter device lat!',
                        },
                    ]}
                >
                    <Input placeholder="Enter Lat" />
                </Form.Item>

                <Form.Item
                    name="lon"
                    label="Device Lon"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter device lon!',
                        },
                    ]}
                >
                    <Input placeholder="Enter Lon" />
                </Form.Item>

                <Form.Item
                    name="assigned_to"
                    label="Customer"
                    rules={[
                        {
                            required: true,
                            message: 'Please select customer!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a customer"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={customers && customers.map(customer => ({
                            value: customer.value, // The ID will be saved as the value
                            label: customer.label, // This is what will be displayed
                        }))}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default DeviceFormModal;
