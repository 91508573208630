import React, { useState, useEffect } from 'react';
import { getDevice, getDeviceDataByID, updateDevice } from '../../services/deviceService';
import { getClients } from '../../services/clientService';
import DataTable from 'react-data-table-component';
import { showError, showSuccess } from '../../services/toastService';
import dayjs from 'dayjs'; // A library for date manipulation
import Chart from '../../components/chart';
import { useLocation } from 'react-router-dom';
import HighChartJs from '../../components/chart/highchart';


function GraphicalUI({ userData }) {

    // console.log(userData)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const deviceId = queryParams.get('device');

    const [devices, setDevices] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentDevice, setCurrentDevice] = useState();
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [graphBVData, setGraphBVData] = useState();
    const [macAddress, setMacAddress] = useState('');
    const [deviceName, setDeviceName] = useState('');

    const goToPreviousDay = async () => {
        setCurrentDate(currentDate.subtract(1, 'day'));

    };

    const goToNextDay = async () => {
        setCurrentDate(currentDate.add(1, 'day'));
        getDeviceData()
    };

    const fetchDevice = async () => {
        try {
            setLoading(true);
            const response = await getDevice()
            setDevices(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDevice();
        getDeviceData();
    }, []);


    const getDeviceData = async () => {
        try {
            setLoading(true);
            // setGraphBVData(null)
            // const device_id = '396';
            const response = await getDeviceDataByID(deviceId)
            // console.log(response)
            setGraphBVData(response.data)
            setMacAddress(response.mac)
            setDeviceName(response.type)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{deviceName} : <small>{macAddress}</small></h3>
                            <div className='float-right'>

                            </div>
                        </div>
                        <div className="card-body">
                            <div className='col-12 text-center'>
                                <div className="row">
                                    <div className='col-3'>
                                        <i className="fa fa-arrow-left float-right" onClick={goToPreviousDay} style={{ fontSize: 25 }}></i>
                                    </div>
                                    <div className='col-6'>
                                        <div style={{ margin: '0 20px' }}>
                                            <input value={currentDate.format('DD-MM-YYYY')} readOnly style={{ textAlign: 'center' }} />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <i className="fa fa-arrow-right float-left" onClick={goToNextDay} style={{ fontSize: 25 }}></i>
                                    </div>
                                </div>
                            </div>

                            {loading && (
                                <div className="loading-container">
                                    <div className="spinner"></div>
                                    <div>Loading ...</div>
                                </div>
                            )}

                            {
                                graphBVData &&
                                <>
                                    <HighChartJs data={graphBVData} title="Ice Temperature" valueField="icetemp" chartdiv={`chart2`} />
                                    <HighChartJs data={graphBVData} title="P V Temp" valueField="pvtemp" chartdiv={`chart5`} />
                                    <HighChartJs data={graphBVData} title="Compressor Speed" valueField="comp" chartdiv={`chart3`} />
                                    <HighChartJs data={graphBVData} title="DCVT" valueField="dcvt" chartdiv={`chart4`} />
                                    <HighChartJs data={graphBVData} title="Battery Voltage" valueField="batteryvolt" chartdiv={`chart1`} />
                                </>
                            }

                        </div>

                    </div>
                </div>
            </div>

        </div >
    );
}

export default GraphicalUI;
