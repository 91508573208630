import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import { createClient, updateClient } from '../../services/clientService';
import { showError, showSuccess } from '../../services/toastService';

const CustomerFormModal = ({ isVisible, onClose, clientData, nextId }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    // console.log(nextId)

    useEffect(() => {
        if (clientData) {
            form.setFieldsValue({
                customer_id: clientData.customer_id || nextId,
                name: clientData.name || '',
                email: clientData.email || '',
                phone_number: clientData.phone_number || '',
                username: clientData.username || '',
                password: '',
            });
        }
    }, [clientData, form]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            let response;
            if (!clientData || !clientData.id) {
                // Create a new client
                response = await createClient(values);
            } else {
                // Update existing client
                response = await updateClient(clientData.id, values);
            }

            if (response.data.status) {
                showSuccess(response.data.message);
                form.resetFields();
                onClose();
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                switch (error.response.status) {
                    case 409:
                        showError('Client with this email/username already exists');
                        break;
                    case 400:
                        showError('Invalid data provided');
                        break;
                    case 404:
                        showError('Client not found');
                        break;
                    default:
                        showError('An unexpected error occurred');
                        break;
                }
            } else {
                showError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Customer Information"
            open={isVisible}
            onOk={handleSubmit}
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            okText={clientData && clientData.id ? 'Update Customer' : 'Add Customer'}
            cancelText="Cancel"
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                name="customerForm"
                initialValues={clientData || { customer_id: nextId, name: '', username: '', password: '' }}
            >
                <Form.Item
                    name="customer_id"
                    label="Customer ID"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the customer id!',
                        },
                    ]}
                >
                    <Input placeholder="Customer ID" readOnly />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name!',
                        },
                    ]}
                >
                    <Input placeholder="Enter name" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the email!',
                        },
                        {
                            type: 'email',
                            message: 'Please enter a valid email!',
                        },
                    ]}
                >
                    <Input placeholder="Enter email" type='email' />
                </Form.Item>

                <Form.Item
                    name="phone_number"
                    label="Phone Number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the phone number!',
                        },
                        {
                            pattern: /^[6-9]\d{9}$/,
                            message: 'Phone number must be 10 digits and start with 6, 7, 8, or 9!',
                        },
                    ]}
                >
                    <Input placeholder="Enter phone number" maxLength={10} />
                </Form.Item>

                <Form.Item
                    name="username"
                    label="Portal Username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the username!',
                        },
                    ]}
                >
                    <Input placeholder="Enter username" />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Portal Password"
                    rules={[
                        {
                            required: clientData?.id ? false : true,
                            message: 'Please input the password!',
                        },
                    ]}
                >
                    <Input.Password placeholder="Enter password" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CustomerFormModal;
