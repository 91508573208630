import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const defaultLocation = { lat: 20.5937, lng: 78.9629 }; // Example coordinates for India
const zoom = 5;


const MapComponent = ({ location }) => {
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAP_KEY
    });

    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const [infoWindowContent, setInfoWindowContent] = useState('');
    const [showInfoWindow, setShowInfoWindow] = useState(false);

    useEffect(() => {
        if (isLoaded && !loadError && mapRef.current) {
            loadMaps();
        }
    }, [isLoaded, loadError, location]);

    const loadMaps = () => {
        // If location is (0, 0), set it to defaultLocation
        if (location.lat === 0 && location.lng === 0) {
            location = defaultLocation;
        }

        // Remove previous marker if it exists
        if (markerRef.current) {
            markerRef.current.setMap(null);
        }

        markerRef.current = new window.google.maps.Marker({
            position: location,
            map: mapRef.current,
            title: 'Device Details', // Optional: Add a title to your marker
        });

        // Add click event listener to the marker
        markerRef.current.addListener('click', () => {
            setInfoWindowContent(location?.device); // Set the content of the InfoWindow
            setShowInfoWindow(true);
        });
    };

    const onLoad = (map) => {
        mapRef.current = map;
        loadMaps();
    };

    const onUnmount = () => {
        mapRef.current = null;
    };

    if (loadError) {
        return <div>Error loading Google Maps API</div>;
    }

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{ width: '100%', height: '400px' }}
            center={location}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                disableDefaultUI: true, // Disable default UI controls
                mapTypeControl: false, // Disable map type control
            }}
        >
            <Marker position={location} />
            {showInfoWindow && (
                <InfoWindow
                    position={location}
                    onCloseClick={() => setShowInfoWindow(false)}
                >
                    <div>{infoWindowContent}</div>
                </InfoWindow>
            )}
        </GoogleMap>
    ) : (
        <div>Loading...</div>
    );
};

export default MapComponent;
