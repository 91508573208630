import React, { useState, useEffect } from 'react';
import { getClients } from '../../services/clientService';
import DataTable from 'react-data-table-component';
import { showError, showSuccess } from '../../services/toastService';
import CustomerFormModal from './form';
import { Button, Space, Table } from 'antd';
import { formatId } from '../../util';

function ClientsTable() {
    const [clients, setClients] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentClient, setCurrentClient] = useState();
    const [nextRunningID, setNextRunningID] = useState(0);

    const columns = [
        {
            name: 'Customer ID',
            selector: row => row.customer_id,
            sortable: true,
            // name: 'Customer ID',
            // dataIndex: 'customer_id',
            // key: 'id',
        },
        {
            name: 'Customer Name',
            selector: row => row.name,
            sortable: true,
            // title: 'Customer Name',
            // dataIndex: 'name',
            // key: 'name',
        },

        {
            name: 'Portal Username',
            selector: row => row.username,
            sortable: true,
            // title: 'Portal Username',
            // dataIndex: 'username',
            // key: 'username',
        },
        {
            name: 'Actions',
            cell: (row) => (
                <Button type='dashed' onClick={() => handleEditClick(row)}>
                    <i className='fa fa-edit'></i> Edit
                </Button>
            ),
            // title: 'Action',
            // key: 'action',
            // render: (_, record) => (
            //     <Space size="middle">
            //         <Button type='dashed' onClick={() => handleEditClick(record)}>
            //             <i className='fa fa-edit'></i> Edit
            //         </Button>
            //     </Space>
            // ),
        },

    ];

    const fetchClients = async () => {
        try {
            setLoading(true);
            const response = await getClients()
            setClients(response.data);
            console.log(response.count)
            setNextRunningID(response.count)
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClients();
    }, [isModalOpen]);

    useEffect(() => {
        fetchClients();
    }, []);


    const handleEditClick = (client) => {
        setCurrentClient(client)
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        // fetchClients()
    }

    const handleAddClick = async () => {
        setCurrentClient({ id: 0, customer_id: formatId(nextRunningID + 2), name: '', username: '', password: '' });
        setIsModalOpen(true)
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
            },
        },
    };

    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Clients</h3>
                            <div className='float-right'>
                                <Button type='primary' onClick={handleAddClick}>
                                    <i className='fa fa-plus'></i> Add Client
                                </Button>
                            </div>
                        </div>
                        <div className="card-body">
                            <DataTable
                                //title="Clients"
                                columns={columns}
                                data={clients}
                                progressPending={loading}
                                pagination
                                // selectableRows
                                highlightOnHover
                                customStyles={customStyles}
                            />
                            {/* <Table
                                rowKey={(record) => record.customer_id}
                                columns={columns}
                                dataSource={clients}
                                loading={loading}
                            /> */}
                        </div>

                    </div>
                </div>
            </div>
            <CustomerFormModal
                isVisible={isModalOpen}
                clientData={currentClient}
                onClose={handleCancel}
                nextId={nextRunningID}
            />
        </div>
    );
}

export default ClientsTable;
