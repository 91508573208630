import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the styles
import Login from './pages/login';
import { useAuth } from './context/AuthContext';
import Dashboard from './pages/dashboard';
import Profile from './pages/profile';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import UnauthenticatedRoutes from './routes/UnauthenticatedRoutes';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min';

// function ProtectedRoute({ element }) {
//   const { isAuthenticated } = useAuth();
//   return isAuthenticated ? element : <Navigate to="/" />;
// }

function App() {

  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <div className="wrapper">
        <Routes>
          {/* Conditional Routes */}
          {isAuthenticated ? (
            <Route path="/*" element={<AuthenticatedRoutes />} />
          ) : (
            <Route path="/*" element={<UnauthenticatedRoutes />} />
          )}
        </Routes>
        <ToastContainer /> {/* Include ToastContainer here */}
      </div>
    </Router>
  );
}

export default App;
