import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/login';

function UnauthenticatedRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            {/* Redirect or add other unauthenticated routes here */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

export default UnauthenticatedRoutes;
