import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;


export const login = async (username, password) => {
    try {
        const response = await axios.post(`${API_URL}login`, { username, password });
        const { token } = response.data;
        localStorage.setItem('token', token); // Store token in local storage
        return token;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
};

export const getProfile = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}profile`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching profile:', error);
        throw error;
    }
};

export function logout() {
    localStorage.removeItem('token'); // Clear the token
    // You can also clear other authentication data if needed
    // const navigate = useNavigate();
    // navigate('/'); // Redirect to login page
}
