import React, { useState, useEffect } from 'react';
import { getDevice, getDeviceDataByID, updateDevice } from '../../services/deviceService';
import { getClients } from '../../services/clientService';
import DataTable from 'react-data-table-component';
import { showError, showSuccess } from '../../services/toastService';
import dayjs from 'dayjs'; // A library for date manipulation

import { useLocation } from 'react-router-dom';



function TabularUI({ userData }) {

    // console.log(userData)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const deviceId = queryParams.get('device');

    const [devices, setDevices] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentDevice, setCurrentDevice] = useState();
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [graphBVData, setGraphBVData] = useState();
    const [macAddress, setMacAddress] = useState('');
    const [deviceName, setDeviceName] = useState('');

    const columns = [
        {
            name: 'Time',
            selector: row => row.Time,
            //sortable: true,
            // name: 'Customer ID',
            // dataIndex: 'customer_id',
            // key: 'id',
        },
        {
            name: 'Compressor',
            selector: row => row.comp,
            //sortable: true,
            // title: 'Customer Name',
            // dataIndex: 'name',
            // key: 'name',
        },

        {
            name: 'Room Temperature 1',
            //selector: row => row.username,
            //sortable: true,
            // title: 'Portal Username',
            // dataIndex: 'username',
            // key: 'username',
        },
        {
            name: 'Room Temperature 2',
            //selector: row => row.username,
            //sortable: true,
            // title: 'Portal Username',
            // dataIndex: 'username',
            // key: 'username',
        },
        {
            name: 'Room Temperature 3',
            //selector: row => row.username,
            //sortable: true,
            // title: 'Portal Username',
            // dataIndex: 'username',
            // key: 'username',
        },
        {
            name: 'Ice Temp',
            selector: row => row.icetemp,
            //sortable: true,
            // title: 'Portal Username',
            // dataIndex: 'username',
            // key: 'username',
        },
        {
            name: 'Battery Voltage',
            selector: row => row.batteryvolt,
            //sortable: true,
            // title: 'Portal Username',
            // dataIndex: 'username',
            // key: 'username',
        },

    ];

    const goToPreviousDay = async () => {
        setCurrentDate(currentDate.subtract(1, 'day'));
    };

    const goToNextDay = async () => {
        setCurrentDate(currentDate.add(1, 'day'));
        getDeviceData()
    };

    const fetchDevice = async () => {
        try {
            setLoading(true);
            const response = await getDevice()
            setDevices(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDevice();
        getDeviceData();
    }, []);


    const getDeviceData = async () => {
        try {
            setLoading(true);
            // setGraphBVData(null)
            // const device_id = '396';
            const response = await getDeviceDataByID(deviceId)
            // console.log(response)
            setGraphBVData(response.data)
            setMacAddress(response.mac)
            setDeviceName(response.type)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    // const customStyles = {
    //     headCells: {
    //         style: {
    //             fontWeight: 'bold',
    //         },
    //     },
    // };
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#f4f4f4',
            },
        },
        table: {
            style: {
                border: '1px solid #f4f4f4',
                marginTop: 20
            },
        },
        headRow: {
            style: {
                //backgroundColor: '#f5f5f5',
                //borderBottom: '1px solid #ddd',
            },
        },
        // rows: {
        //     style: {
        //         borderBottom: '1px solid #ddd',
        //     },
        // },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: '#f4f4f4',
                },
            },
        },
    };

    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{deviceName} : <small>{macAddress}</small></h3>
                            <div className='float-right'>

                            </div>
                        </div>
                        <div className="card-body">
                            <div className='col-12 text-center'>
                                <div className="row">
                                    <div className='col-3'>
                                        <i className="fa fa-arrow-left float-right" onClick={goToPreviousDay} style={{ fontSize: 25 }}></i>
                                    </div>
                                    <div className='col-6'>
                                        <div style={{ margin: '0 20px' }}>
                                            <input value={currentDate.format('DD-MM-YYYY')} readOnly style={{ textAlign: 'center' }} />
                                        </div>
                                    </div>
                                    <div className='col-3  '>
                                        <i className="fa fa-arrow-right float-left" onClick={goToNextDay} style={{ fontSize: 25 }}></i>
                                    </div>
                                </div>
                            </div>

                            {loading && (
                                <div className="loading-container">
                                    <div className="spinner"></div>
                                    <div>Loading ...</div>
                                </div>
                            )}

                            {
                                graphBVData &&
                                <>
                                    <DataTable
                                        columns={columns}
                                        data={graphBVData}
                                        progressPending={loading}
                                        pagination
                                        // selectableRows
                                        highlightOnHover
                                        customStyles={customStyles}
                                    />
                                </>
                            }

                        </div>

                    </div>
                </div>
            </div>

        </div >
    );
}

export default TabularUI;
