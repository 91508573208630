import React, { createContext, useState, useEffect, useContext, navigate } from 'react';
import { getProfile } from '../auth'; // Make sure this file is correctly imported

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    // Check token on component mount
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    await getProfile(); // Verify token validity by making a request
                    setIsAuthenticated(true);
                } catch (error) {
                    setIsAuthenticated(false);
                    localStorage.removeItem('token'); // Clear invalid token
                }
            } else {
                setIsAuthenticated(false);
            }
            setLoading(false);
        };

        checkAuth();
    }, []);

    const login = (token) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        //navigate('/dashboard'); // Redirect on successful login
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        //navigate('/login'); // Redirect to login page
    };

    if (loading) return <div>Loading...</div>; // Optional: show a loading indicator

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
