import React from 'react';

function Navigation({ userData }) {
    return (
        userData && <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                            <i className="fas fa-bars"></i>
                        </a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        {/* <a href="#" className="nav-link">Customer name Admin Panel</a> */}
                    </li>
                </ul>
                <h4 className='text-center' style={{ width: '95%' }}>
                    {userData?.role === "SUPERADMIN" && 'Pluss - Master Admin Panel'}
                    {userData?.role === "CLIENT" && 'Client Panel'}
                </h4>
            </nav>
        </>
    );
}

export default Navigation;