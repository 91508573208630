import React, { useState } from 'react';
import { login as apiLogin } from '../../auth';
import { useAuth } from '../../context/AuthContext';
import { showError, showSuccess } from '../../services/toastService';
import { Button } from 'antd';


function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [error, setError] = useState('');
    const { login } = useAuth();
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const token = await apiLogin(username, password);
            login(token); // Update auth context and redirect
            showSuccess('Login successful!');
        } catch (error) {
            console.log(error)
            showError('Invalid credentials'); // Show error message
        } finally {
            setLoading(false)
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle the showPassword state
    };

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <a href="#">
                        <img src="assets/images/pluss.png" alt="AdminLTE Logo" className=" brand-image" />
                    </a>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        <h3 className="login-box-msg">Login</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />

                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type={showPassword ? 'text' : 'password'} // Toggle input type based on state
                                    className="form-control"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Button type="primary" block onClick={handleSubmit} loading={loading}>Log In</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="login-logo mt-4">
                    <img src="assets/images/himacool.png" alt="AdminLTE Logo" className=" brand-image" height={25} />
                </div>
            </div>
        </div>
    );
}

export default Login;
