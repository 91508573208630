import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import Navigation from '../components/nav'
import Aside from '../components/aside'
import { getProfile } from '../auth';
import { useNavigate } from 'react-router-dom';
import ClientsTable from '../pages/clients';
import DevicesTable from '../pages/devices';
import GraphicalUI from '../pages/graphical';
import Footer from '../components/footer';
import TabularUI from '../pages/tabular';

function AuthenticatedRoutes() {
    const [userData, setUserData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    console.log('logout')
                    const data = await getProfile(); // Verify token validity by making a request
                    setUserData(data)
                } catch (error) {
                    console.log('logout')
                    navigate('/login');
                }
            }
        };
        checkAuth();
    }, []);

    return (
        <>
            {userData && <Navigation userData={userData} />}
            {userData && <Aside userData={userData} />}
            <Routes>
                <Route path="/dashboard" element={<Dashboard userData={userData} />} />
                <Route path="/profile" element={<Profile userData={userData} />} />
                <Route path="/clients" element={<ClientsTable userData={userData} />} />
                <Route path="/devices" element={<DevicesTable userData={userData} />} />
                <Route path="/graphical" element={<GraphicalUI userData={userData} />} />
                <Route path="/tabular" element={<TabularUI userData={userData} />} />
                <Route path="/export" element={<TabularUI userData={userData} />} />
                {/* Redirect or add other authenticated routes here */}
                <Route path="*" element={<Navigate to="/dashboard" />} /> {/* Redirect unknown routes to Dashboard */}
            </Routes>
            <Footer />
        </>
    );
}

export default AuthenticatedRoutes;
