import React from 'react';

function Footer() {
    return (
        <footer className="main-footer">
            <strong>Copyright &copy; 2024 <a href="#">Pluss</a>. </strong>
            All rights reserved.
        </footer>
    );
}

export default Footer;
