import { toast } from 'react-toastify';

// Show success message
export const showSuccess = (message) => {
    toast.success(message, {
        autoClose: 3000, // Duration in milliseconds
    });
};

// Show error message
export const showError = (message) => {
    toast.error(message, {
        //position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Duration in milliseconds
    });
};

// Show info message
export const showInfo = (message) => {
    toast.info(message, {
        //position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Duration in milliseconds
    });
};

// Show warning message
export const showWarning = (message) => {
    toast.warn(message, {
        //position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Duration in milliseconds
    });
};
