import React from 'react';
import MapComponent from '../../components/gmap';


function Dashboard({ userData }) {
    //console.log(userData);
    let location = {
        lat: 20.5937,
        lng: 78.9629
    };
    if (userData?.deviceList.length > 0 && userData?.deviceList[0].lat && userData?.deviceList[0].lon) {
        location = {
            lat: parseFloat(userData.deviceList[0].lat),
            lng: parseFloat(userData.deviceList[0].lon),
            device: userData.deviceList[0].name
        };
    }
    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Dashboard</h3>
                        </div>
                        <div className="card-body">
                            <MapComponent location={location} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
