import React from 'react';

function Profile() {
    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    <h1>Profile</h1>
                    <p>This is the user profile page.</p>
                </div>
            </section>
        </div>
    );
}

export default Profile;
